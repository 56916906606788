<script setup lang="js">
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import CriterionCreateModal from '@/components/modals/CriterionCreateModal.vue';
import CriterionEditModal from '@/components/modals/CriterionEditModal.vue';
import OptionEditModal from '@/components/modals/OptionEditModal.vue';
import AddOptionModal from '@/components/modals/AddOptionModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { storeToRefs } from 'pinia';
import { useCriterionStore } from '@/stores/criterion.store';
import { onMounted } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useProjectStore } from '@/stores/project.store';

const { activeModal, openModal, closeModal } = useModal();
const criterionStore = useCriterionStore();
const {
  criterionList,
  paginatedOptionList,
  selectedOptions,
  selectedCriterionId,
  isSelectAll,
  selectedOptionId,
  selectedCriterion,
  selectedOption,
  getCriterionListRequest
} = storeToRefs(criterionStore);
const projectId = useRouteParams('projectId');
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

onMounted(() => {
  selectedCriterionId.value = null;
  criterionStore.getCriterionList(projectId.value);
  resetSelection();
});

function resetSelection() {
  selectedOptionId.value = null;
  selectedCriterion.value = null;
  selectedOption.value = null;
  selectedOptions.value = [];
  isSelectAll.value = false;
}

function toggleCriterion(id) {
  selectedCriterionId.value = id;
  resetSelection();
  criterionStore.getPaginatedOptionList(projectId.value, selectedCriterionId.value);
}

function selectAll() {
  const isActive = isSelectAll.value;
  if (!isActive) {
    const services = paginatedOptionList.value.data;
    services.forEach((option) => {
      if (!selectedOptions.value.includes(option.id)) {
        selectedOptions.value.push(option.id);
      }
    });
  } else {
    selectedOptions.value = [];
  }
  isSelectAll.value = !isActive;
}

function handleClick(option, modal) {
  selectedOptionId.value = option.id;
  selectedOption.value = option;
  activeModal.value = modal;
}

function selectItem(itemId) {
  if (selectedOptions.value.includes(itemId)) {
    selectedOptions.value = selectedOptions.value.filter((option) => option != itemId);
  } else {
    selectedOptions.value.push(itemId);
  }
}
function handlePageChange(page) {
  criterionStore.getPaginatedOptionList(projectId.value, selectedCriterionId.value, page);
}
function openEditModal(criterion) {
  selectedCriterion.value = criterion;
  openModal('criterionEditModal');
}
function deleteOption() {
  criterionStore
    .deleteOption(projectId.value, selectedCriterionId.value, selectedOptionId.value)
    .then(() => {
      closeModal();
    });
}
function deleteAll() {
  selectedOptions.value.forEach((optionId) => {
    criterionStore.deleteOption(optionId);
  });
  selectedOptions.value = [];
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 projects_filter-block">
        <div class="d-flex align-items-center flex-wrap criterion-tabs">
          <div v-if="getCriterionListRequest.isLoading" class="d-flex justify-content-center">
            <img class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
          </div>
          <div
            v-for="criterion in criterionList"
            :key="criterion.id"
            class="criterion-tab d-flex align-items-center justify-content-between"
            :class="{ 'criterion-tab-active': criterion.id === selectedCriterionId }"
            @click="toggleCriterion(criterion.id)"
          >
            <span>{{ criterion.title[project.default_locale] }}</span>
            <img
              src="@/assets/img/edit-ico.svg"
              alt="edit-ico.svg"
              @click="openEditModal(criterion)"
            />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <span
            class="delete-selected-btn"
            @click="deleteAll()"
            v-show="selectedOptions.length > 0"
          >
            Удалить выбранные
          </span>
          <div
            v-if="selectedCriterionId"
            @click="openModal('addOptionModal')"
            class="orange-border-btn"
          >
            <img src="@/assets/img/plus-ico-gray.svg" alt="" /> Добавить опцию
          </div>
          <div @click="openModal('criterionCreateModal')" class="orange-btn">
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать критерий
          </div>
        </div>
      </div>
      <div v-if="selectedCriterionId" class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th class="checkbox-td">
                <span
                  @click="selectAll()"
                  class="table-checkbox"
                  :class="{ active: isSelectAll }"
                />
              </th>
              <th>
                <span class="table-num">id</span>
              </th>
              <th v-for="locale in project.locales" :key="locale.id">
                Наименование на {{ locale.name }}
              </th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="option in paginatedOptionList.data"
              :key="option.id"
              :class="{ selected: selectedOptions.includes(option.id) }"
            >
              <td class="checkbox-td">
                <span @click="selectItem(option.id)" class="table-checkbox" />
              </td>
              <td>
                <span class="table-num">{{ option.id }}</span>
              </td>
              <td v-for="locale in project.locales" :key="locale.id">
                {{ option.title[locale.id] }}
              </td>
              <td class="table-buttons">
                <div class="table-button" @click="handleClick(option, 'optionEditModal')">
                  <span class="edit-ico" />
                </div>
                <div class="table-button" @click="handleClick(option, 'deleteModal')">
                  <span class="delite-ico" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-3">
        <Bootstrap5Pagination
          :data="paginatedOptionList"
          @pagination-change-page="handlePageChange"
          :show-disabled="true"
          :limit="2"
        />
      </div>
      <CriterionCreateModal v-if="activeModal === 'criterionCreateModal'" />
      <CriterionEditModal v-if="activeModal === 'criterionEditModal'" />
      <AddOptionModal v-if="activeModal === 'addOptionModal'" />
      <OptionEditModal v-if="activeModal === 'optionEditModal'" :optionId="selectedOptionId" />
      <DeleteModal
        v-if="activeModal === 'deleteModal'"
        :itemText="'эту опцию'"
        :title="selectedOption.title[project.default_locale]"
        @confirmDelete="deleteOption()"
      />
    </div>
  </div>
</template>
