<script setup lang="js">
import { computed, onMounted } from 'vue';
import { useServiceGroupStore } from '@/stores/serviceGroup.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ServiceGroupModal from '@/components/modals/ServiceGroupModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { useProjectStore } from '@/stores/project.store';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import Search from '@/components/Search.vue';
import { useRoute, useRouter } from 'vue-router';
import ServiceGroupAddServiceModal from '@/components/modals/ServiceGroupAddServiceModal.vue';

const router = useRouter();
const route = useRoute();
const serviceGroupStore = useServiceGroupStore();
const projectId = useRouteParams('projectId');
const groupId = useRouteParams('serviceGroupId');

const { activeModal, openModal, closeModal } = useModal();

const {
  searchName,
  paginatedServiceGroupSubList,
  serviceGroupSubType,
  getServiceGroupSubListRequest,
  currentParentGroup,
  selectedSubItem,
  isSelectGroupSubAll,
  selectedServiceGroupSubs,
  serviceGroup
} = storeToRefs(serviceGroupStore);

const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

const backUrl = computed(() => {
  if (currentParentGroup.value.parent_id) {
    return router.resolve({
      name: 'service-group-sub-list',
      params: {
        projectId: projectId.value,
        serviceGroupId: currentParentGroup.value.parent_id
      }
    }).href;
  } else {
    return router.resolve({
      name: 'service-group-list',
      params: {
        projectId: projectId.value
      }
    }).href;
  }
});

onMounted(() => {
  searchName.value = '';
  serviceGroupStore.getPaginatedServiceGroupSubList(projectId.value, groupId.value);
  projectStore.getProject(route.params.projectId);
  serviceGroupStore.getServiceGroup(projectId.value, groupId.value).then((group) => {
    currentParentGroup.value = group;
  });
});

function selectItem(itemId) {
  if (selectedServiceGroupSubs.value.includes(itemId)) {
    selectedServiceGroupSubs.value = selectedServiceGroupSubs.value.filter(
      (service) => service != itemId
    );
  } else {
    selectedServiceGroupSubs.value.push(itemId);
  }
}
function selectAll() {
  const isActive = isSelectGroupSubAll.value;
  if (!isActive) {
    const groups = paginatedServiceGroupSubList.value.data;
    groups.forEach((service) => {
      if (!selectedServiceGroupSubs.value.includes(service.id)) {
        selectedServiceGroupSubs.value.push(service.id);
      }
    });
  } else {
    selectedServiceGroupSubs.value = [];
  }
  isSelectGroupSubAll.value = !isActive;
}
function handleClick(item, modal) {
  selectedSubItem.value = item;
  activeModal.value = modal;
}
function openUpdateModal(group) {
  serviceGroup.value = group;
  activeModal.value = 'serviceGroupUpdateModal';
}
function handlePageChange(page) {
  serviceGroupStore.getPaginatedServiceGroupSubList(projectId.value, groupId.value, page);
}
async function deleteServiceGroup() {
  await serviceGroupStore.deleteServiceGroup(projectId.value, selectedSubItem.value.id);
  serviceGroupStore.getPaginatedServiceGroupSubList(projectId.value, groupId.value);
  closeModal();
}
function detachService() {
  serviceGroupStore
    .detachServices(projectId.value, currentParentGroup.value.id, [selectedSubItem.value.id])
    .then(() => {
      closeModal();
      serviceGroupStore.getPaginatedServiceGroupSubList(projectId.value, groupId.value);
    });
}

function redirectTo(projectId, serviceGroupId) {
  if (serviceGroupSubType.value !== 'subgroups') {
    return;
  }

  router.push({
    name: 'service-group-sub-list',
    params: {
      projectId,
      serviceGroupId
    }
  });
}
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex mt-3 page-header">
      <RouterLink :to="backUrl" class="back-btn" />
      <h1 v-if="currentParentGroup.title">
        {{ currentParentGroup.title[project.default_locale] }}
      </h1>
    </div>
  </div>
  <div class="container-fluid subgroup-page">
    <div class="row">
      <div
        class="d-flex justify-content-between align-items-center mb-4 flex-wrap projects_filter-block"
      >
        <div class="d-flex align-items-center flex-wrap">
          <Search
            @search="serviceGroupStore.getPaginatedServiceGroupSubList(projectId, groupId)"
            :value="searchName"
            :placeholderText="'Поиск по названию'"
            @input="searchName = $event.target.value"
          />
          <!-- <Sort :value="sortType" @applyChange="changeSortType" />
          <span class="delete-selected-btn" @click="deleteAll()" v-show="selectedServices.length"
            >Удалить выбранные</span
          > -->
        </div>
        <div class="d-flex align-items-center gap-1">
          <Bootstrap5Pagination
            :data="paginatedServiceGroupSubList"
            @pagination-change-page="handlePageChange"
            :show-disabled="true"
            :limit="2"
          />
          <!-- add two modals for adding subgroup and subservice -->
          <div
            v-if="['undefined', 'services'].includes(serviceGroupSubType)"
            @click="openModal('serviceGroupAddServiceModal')"
            class="orange-border-btn"
          >
          <img src="@/assets/img/plus-ico-gray.svg" alt="" /> Добавить услуги
          </div>

          <div
            v-if="['undefined', 'subgroups'].includes(serviceGroupSubType)"
            @click="openModal('serviceGroupSubCreateModal')"
            class="orange-btn"
          >
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать группу
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th class="checkbox-td">
                <span
                  @click="selectAll()"
                  class="table-checkbox"
                  :class="{ active: isSelectGroupAll }"
                />
              </th>
              <th>
                Наименование
                <span v-if="serviceGroupSubType === 'subgroups'">подгруппы</span>
                <span v-if="serviceGroupSubType === 'services'">услуги</span>
                <span v-if="serviceGroupSubType === 'undefined'">подгруппы/услуги</span>
              </th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="getServiceGroupSubListRequest.isLoading">
              <td colspan="5">
                <div class="d-flex justify-content-center">
                  <img class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
                </div>
              </td>
            </tr>
            <tr
              v-for="item in paginatedServiceGroupSubList.data"
              :key="item.id"
              @click="redirectTo(projectId, item.id)"
              :class="{ selected: selectedServiceGroupSubs.includes(item.id) }"
            >
              <td class="checkbox-td">
                <span @click.stop="selectItem(item.id)" class="table-checkbox name"></span>
              </td>
              <td>
                {{ item.title[project.default_locale] }}
              </td>
              <td>
                <div class="table-buttons">
                  <template v-if="serviceGroupSubType === 'subgroups'">
                    <div class="table-button" @click.stop="openUpdateModal(item)">
                      <span class="edit-ico" />
                    </div>
                    <div class="table-button" @click.stop="handleClick(item, 'deleteModal')">
                      <span class="delite-ico" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="table-button" @click.stop="handleClick(item, 'detachServiceModal')">
                      <span class="delite-ico" />
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <DeleteModal
        v-if="activeModal === 'deleteModal'"
        :itemText="'группу'"
        :title="selectedSubItem.title[project.default_locale]"
        @confirmDelete="deleteServiceGroup()"
      />

      <DeleteModal
        v-if="activeModal === 'detachServiceModal'"
        :itemText="'услугу'"
        :title="selectedSubItem.title[project.default_locale]"
        @confirmDelete="detachService()"
      />

      <ServiceGroupModal
        v-if="
          activeModal === 'serviceGroupSubCreateModal' || activeModal === 'serviceGroupUpdateModal'
        "
        @action="
          serviceGroupStore.getPaginatedServiceGroupSubList(projectId, currentParentGroup.id)
        "
      />

      <ServiceGroupAddServiceModal v-if="activeModal === 'serviceGroupAddServiceModal'" />
    </div>
  </div>
</template>
