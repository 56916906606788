import _ from 'lodash';

const root = import.meta.env.VITE_VELOX_BASE_URL;

function api<T extends Record<string, number | string>>(
  strings: TemplateStringsArray,
  ...keys: (keyof T | Function)[]
) {
  return (values: Partial<T> = {}, test: boolean = false, nested = false) => {
    if (keys.length && !_.isPlainObject(values) && !test) {
      throw new Error('must be object');
    }

    const result = [strings[0]];

    if (!nested) {
      result.unshift(root);
    }

    keys.forEach(function (key, i) {
      if (_.isUndefined(key)) {
        throw new Error('key of route is undefined');
      }

      if (key instanceof Function) {
        result.push(key(values, test, true));
      } else if (test) {
        result.push('{', String(key), '}');
      } else if (key in values) {
        result.push(String(values[key]));
      } else {
        result.push(String(key));
      }

      result.push(strings[i + 1]);
    });

    return result.join('');
  };
}

type IProject = { projectId: number | string };
type ISubmoduleGroup = { submoduleGroupId: number | string };
type IEndSubmodule = { endSubmoduleId: number | string };
type ICommonSubmodule = { commonSubmoduleId: number | string };
type IService = IProject & { serviceId: number | string };
type ICriterion = IProject & { criterionId: number | string };
type IOption = ICriterion & { optionId: number | string };
type IBusinessProcess = IProject & { bpId: number | string };
type INode = IBusinessProcess & { nodeId: number | string };
type IInputField = INode & { inputFieldId: number | string };
type ICatalogField = INode & { inputCatalogId: number | string };
type ITableField = INode & { inputTableId: number | string };
type IColumn = ITableField & { columnId: number | string };
type IStaticField = INode & { inputStaticId: number | string };
type IRoute = IService & { routeId: number | string };
type IPassportBlock = IRoute & { passportBlockId: number | string };
type IBpNodeId = IRoute & { bpNodeId: number | string };
type IInputDocuments = INode & { inputDocuments: number | string };
type IViewDocuments = INode & { viewDocuments: number | string };
type IInputDocumentsId = IInputDocuments & { inputDocId: number | string };
type IInputDocTemplateId = IInputDocuments & { inputDocTemplateId: number | string };
type IInputDocDirectionId = IInputDocuments & { directionId: number | string };
type INodeDirectional = INode & { nodeDirectionId: number | string };

type IInputGeometriesId = INode & { inputGeomId: number | string };
type IInputGeomDirectionId = INode & { directionId: number | string };
// type IViewGeometries = INode & { viewGeometries: number | string };

type IInputGeometryCatalogId = INode & { inputGeomCatalogId: number | string };
type IInputGeomCatalogDirectionId = INode & { directionId: number | string };

type IUser = { userId: number | string };
type IRegTable = IProject & { regTableId: number | string };
type IRegTableColumn = IRegTable & { regTableColumnId: number | string };
type IRegTableRef = IRegTable & { regTableRefId: number | string };
type IChildNodeId = INode & { childNodeId: number | string };
type IAutoforms = INode & { autoforms: number | string };
type IAutoformId = IAutoforms & { autoformId: number | string };
type IVirtualMachine = IProject & { virtualMachineId: number | string };
type IProjectSubmoduleGroup = IProject & { submoduleGroupId: number | string };
type ILayers = INode & { layerId: number | string };
type IServiceGroup = IProject & { serviceGroupId: number | string };
type IReturn = INode & { returnBpNodeId: number | string };

const auth = api`/auth`;
export const logout = api`${auth}/logout`;
export const login = api`${auth}/login`;
export const me = api`${auth}/me`;
export const locales = api`/locales`;
export const projects = api`/projects`;
export const projectById = api<IProject>`${projects}/${'projectId'}`;
export const services = api<IProject>`${projectById}/services`;
export const serviceById = api<IService>`${services}/${'serviceId'}`;
export const serviceRoute = api<IService>`${serviceById}/routes`;
export const serviceRouteById = api<IRoute>`${serviceRoute}/${'routeId'}`;
export const passportBlocks = api<IRoute>`${serviceRouteById}/passport_blocks`;
export const copyPassportBlocks = api<IRoute>`${passportBlocks}/copy`;
export const passportBlockById = api<IPassportBlock>`${passportBlocks}/modify/${'passportBlockId'}`;
export const serviceRouteNodes = api<IRoute>`${serviceRouteById}/nodes`;
export const nodeOrganizations = api<IBpNodeId>`${serviceRouteNodes}/${'bpNodeId'}/organization`;
export const serviceCriterion = api<IService>`${serviceById}/criterions`;
export const businessProcess = api<IProject>`${projectById}/business_processes`;
export const businessProcessById = api<IBusinessProcess>`${businessProcess}/${'bpId'}`;
export const copyBusinessProcess = api<IBusinessProcess>`${businessProcessById}/copy`;
export const fixateBusinessProcess = api`${businessProcessById}/fixate`;
export const validateBusinessProcess = api`${businessProcessById}/validate`;
export const criterions = api<IProject>`${projectById}/criterions`;
export const criterionById = api<ICriterion>`${criterions}/${'criterionId'}`;
export const options = api<ICriterion>`${criterionById}/options`;
export const optionById = api<IOption>`${options}/${'optionId'}`;
export const organizations = api<IProject>`${projectById}/organizations`;
export const nodes = api<IBusinessProcess>`${businessProcessById}/nodes`;
export const nodeById = api<INode>`${nodes}/${'nodeId'}`;
export const attachNode = api<IChildNodeId>`${nodeById}/next_nodes/${'childNodeId'}`;
export const deattachNode = api<IChildNodeId>`${nodeById}/next_nodes/${'childNodeId'}`;
export const inputFields = api<IInputField>`${nodeById}/input_fields`;
export const inputFieldById = api<IInputField>`${inputFields}/${'inputFieldId'}`;
export const inputDocuments = api<IInputDocuments>`${nodeById}/input_documents`;
export const inputDocumentsBasicTemplate = api`${inputDocuments}/basic_template`;
export const inputDocumentsOrder = api<IInputDocuments>`${inputDocuments}/order`;
export const inputDocumentsAncestors = api<IInputDocuments>`${inputDocuments}/ancestors`;
export const inputDocumentsDirectional = api<IInputDocDirectionId>`${inputDocuments}/directional/${'directionId'}`;
export const inputDocumentsById = api<IInputDocumentsId>`${inputDocuments}/${'inputDocId'}`;
export const inputDocumentTemplates = api<IInputDocumentsId>`${inputDocumentsById}/templates`;
export const inputDocumentTemplateById = api<IInputDocTemplateId>`${inputDocumentTemplates}/${'inputDocTemplateId'}`;
export const deleteInputDocumentTemplateById = api<IInputDocTemplateId>`${inputDocumentTemplates}/delete/${'inputDocTemplateId'}`;
export const viewDocuments = api<IViewDocuments>`${nodeById}/view_documents`;
export const viewDocumentsOrder = api<IViewDocuments>`${viewDocuments}/order`;
export const formInput = api`${nodeById}/form/input/list`;
export const formInputAncestors = api`${nodeById}/form/input/ancestors`;
export const formInputOrder = api`${nodeById}/form/input/order`;
export const formInputDirectional = api<INodeDirectional>`${nodeById}/form/input/directional/${'nodeDirectionId'}`;
export const inputCatalog = api`${nodeById}/input_catalogs`;
export const inputCatalogById = api<ICatalogField>`${inputCatalog}/${'inputCatalogId'}`;
export const inputTable = api`${nodeById}/input_tables`;
export const inputTableById = api<ITableField>`${inputTable}/${'inputTableId'}`;
export const column = api`${inputTableById}/columns`;
export const columnById = api<IColumn>`${column}/${'columnId'}`;
export const columnOrder = api`${column}/order`;
export const inputStatic = api`${nodeById}/input_statics`;
export const inputStaticById = api<IStaticField>`${inputStatic}/${'inputStaticId'}`;
export const formView = api`${nodeById}/form/view/list`;
export const formViewUpdate = api`${nodeById}/form/view`;
export const formViewOrder = api`${nodeById}/form/view/order`;

export const inputGeometries = api<INode>`${nodeById}/input_geometries`;
export const inputGeometriesById = api<IInputGeometriesId>`${inputGeometries}/${'inputGeomId'}`;
export const inputGeometriesAncestors = api<INode>`${inputGeometries}/ancestors`;
export const inputGeometriesOrder = api<INode>`${inputGeometries}/order`;
export const inputGeometriesDirectional = api<IInputGeomDirectionId>`${inputGeometries}/directional/${'directionId'}`;
export const viewGeometries = api<INode>`${nodeById}/view_geometries`;
export const viewGeometriesOrder = api<INode>`${viewGeometries}/order`;

export const inputGeometryCatalogs = api<INode>`${nodeById}/input_geometry_catalogs`;
export const inputGeometryCatalogById = api<IInputGeometryCatalogId>`${inputGeometryCatalogs}/${'inputGeomCatalogId'}`;
export const inputGeometryCatalogAncestors = api<INode>`${inputGeometryCatalogs}/ancestors`;
export const inputGeometryCatalogOrder = api<INode>`${inputGeometryCatalogs}/order`;
export const inputGeometryCatalogDirectional = api<IInputGeomCatalogDirectionId>`${inputGeometryCatalogs}/directional/${'directionId'}`;
export const viewGeometryCatalogs = api<INode>`${nodeById}/view_geometry_catalogs`;
export const viewGeometryCatalogsOrder = api<INode>`${viewGeometryCatalogs}/order`;

export const users = api`/users`;
export const userById = api<IUser>`${users}/${'userId'}`;
export const userProjects = api<IUser>`${userById}/projects`;
export const permissions = api`/permissions/${'projectId'}`;
export const teamUsers = api<IProject>`${projectById}/users`;
export const registries = api<IProject>`${projectById}/registry`;
export const regTables = api`${registries}/tables`;
export const regTablesFixate = api`${regTables}/fixate`;
export const regTableById = api<IRegTable>`${regTables}/${'regTableId'}`;
export const regTableColumn = api`${regTableById}/columns`;
export const regTableColumnById = api<IRegTableColumn>`${regTableColumn}/${'regTableColumnId'}`;
export const regTableColumnByIdRefer = api`${regTableColumnById}/refer`;
export const regTableRefId = api<IRegTableRef>`${regTableById}/refer/${'regTableRefId'}`;

export const returns = api<INode>`${nodeById}/returns`;
export const returnById = api<IReturn>`${returns}/${'returnBpNodeId'}`;
export const returnAncestors = api<INode>`${nodeById}/ancestors-for-return`;

export const autoforms = api<IAutoforms>`${nodeById}/autoforms`;
export const autoformVariables = api`${autoforms}/variables`;
export const autoformOutputVariables = api`${autoforms}/output_variables`;
export const autoformOperations = api`${autoforms}/operations`;
export const autoformById = api<IAutoformId>`${autoforms}/${'autoformId'}`;
export const uuid = api`/uuid`;
export const submoduleGroups = api`/submodule_groups`;
export const submoduleGroupById = api<ISubmoduleGroup>`${submoduleGroups}/${'submoduleGroupId'}`;
export const endSubmodules = api`${submoduleGroupById}/end_submodules`;
export const endSubmoduleById = api<IEndSubmodule>`${endSubmodules}/${'endSubmoduleId'}`;
export const commonSubmodules = api`${submoduleGroupById}/common_submodules`;
export const commonSubmoduleById = api<ICommonSubmodule>`${commonSubmodules}/${'commonSubmoduleId'}`;
export const autonode = api`${nodeById}/autonode`;
export const autonodeVariables = api`${autonode}/variables`;
export const autonodeRegistries = api`${autonode}/registries`;
export const autonodeOutputVariables = api`${autonode}/output_variables`;
export const autonodeOperations = api`${autonode}/operations`;
export const waitingAutonode = api`${nodeById}/waiting_autonode`;
export const waitingAutonodeVariables = api`${waitingAutonode}/variables`;
export const waitingAutonodeOperations = api`${waitingAutonode}/operations`;
export const virtualMachines = api`${projectById}/virtual_machines`;
export const virtualMachineById = api<IVirtualMachine>`${virtualMachines}/${'virtualMachineId'}`;
export const virtualMachineEnvFile = api<IVirtualMachine>`${virtualMachineById}/env`;
export const virtualMachineDeploy = api`${virtualMachineById}/deploy`;
export const projectSubmoduleGroups = api`${projectById}/submodule_groups`;
export const projectSubmoduleGroupById = api<IProjectSubmoduleGroup>`${projectSubmoduleGroups}/${'submoduleGroupId'}`;
export const layers = api`${nodeById}/layers`;
export const layerById = api<ILayers>`${layers}/${'layerId'}`;
export const serviceGroups = api<IProject>`${projectById}/groups`;
export const serviceGroupById = api<IServiceGroup>`${serviceGroups}/${'serviceGroupId'}`;
export const serviceGroupSubs = api<IServiceGroup>`${serviceGroupById}/subs`;
export const serviceGroupAvailableServices = api<IServiceGroup>`${serviceGroupById}/available-services`;
export const serviceGroupServices = api<IServiceGroup>`${serviceGroupById}/services`;
