<script setup lang="js">
import { onMounted } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
const route = useRoute();
const projectStore = useProjectStore();

onMounted(() => {
  projectStore.getProject(route.params.projectId);
});

const { project } = storeToRefs(projectStore);
</script>
<template>
  <div>
    <div class="container-fluid">
      <div class="d-flex mt-3 page-header">
        <RouterLink :to="{ name: 'project-list' }" class="back-btn" />
        <h1>{{ project.name }}</h1>
      </div>
    </div>
    <div class="project-tabs">
      <RouterLink :to="{ name: 'service-list' }" class="project-tab"> Услуги </RouterLink>
      <RouterLink :to="{ name: 'service-group-list' }" class="project-tab"> Группа услуг </RouterLink>
      <RouterLink :to="{ name: 'bp' }" class="project-tab"> Бизнес-процессы </RouterLink>
      <RouterLink :to="{ name: 'criterion' }" class="project-tab"> Критерии </RouterLink>
      <RouterLink :to="{ name: 'project-info' }" class="project-tab"> Информация </RouterLink>
      <RouterLink :to="{ name: 'team' }" class="project-tab"> Команда </RouterLink>
      <RouterLink :to="{ name: 'registries' }" class="project-tab"> Реестры </RouterLink>
      <RouterLink :to="{ name: 'virtual-machine-list' }" class="project-tab"> Виртуальные машины </RouterLink>
      <RouterLink :to="{ name: 'project-submodule-group-list' }" class="project-tab"> Субмодули </RouterLink>
    </div>
    <router-view :key="route.fullPath" />
  </div>
</template>
