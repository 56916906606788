<script setup lang="js">
import { onMounted, ref, computed } from 'vue';
import { useServiceGroupStore } from '@/stores/serviceGroup.store';
import { useProjectStore } from '@/stores/project.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import { useServiceStore } from '@/stores/service.store';

const serviceGroupStore = useServiceGroupStore();
const projectStore = useProjectStore();
const projectId = useRouteParams('projectId');
const { project } = storeToRefs(projectStore);
const { closeModal, activeModal } = useModal();

const { getGroupAvailableServiceListRequest, attachServicesRequest, currentParentGroup } =
  storeToRefs(serviceGroupStore);

const search = ref('');
const checkedServices = ref([]);

onMounted(() => {
  if (!project.value?.locales) {
    projectStore.getProject(projectId.value);
  }
  getAvailableServices();
});

function getAvailableServices() {
  serviceGroupStore.getGroupAvailableServiceList(
    projectId.value,
    currentParentGroup.value.id,
    search.value
  );
}

function handleSubmit() {
  serviceGroupStore
    .attachServices(projectId.value, currentParentGroup.value.id, checkedServices.value)
    .then((resp) => {
      closeModal();
      serviceGroupStore.getPaginatedServiceGroupSubList(
        projectId.value,
        currentParentGroup.value.id
      );
    });
}
</script>

<template>
  <ModalTemplateSide
    @submit="handleSubmit"
    :disableButtons="attachServicesRequest.isLoading"
  >
    <template v-slot:title>Добавить услуги в группу</template>
    <template v-slot:body>
      <div class="mt-4">
        <Search
          @search="getAvailableServices"
          :value="search"
          :placeholderText="'Поиск по названию'"
          @input="search = $event.target.value"
        />
      </div>

      <div
        v-for="service in getGroupAvailableServiceListRequest.value"
        :key="service.id"
        class="available-service-item"
      >
        <label :for="service.id" class="d-flex align-items-center">
          <input v-model="checkedServices" type="checkbox" :id="service.id" :value="service.id" />
          <div>{{ service.title[project.default_locale] }}</div>
        </label>
      </div>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>

<style scoped>
.available-service-item {
  font-size: 18px;
  font-weight: 400;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #dddddd;
}

.available-service-item > label {
  cursor: pointer;
}

.available-service-item > label:hover {
  background-color: rgb(233, 233, 233);
}

.available-service-item > label > input {
  margin: 1.5rem 0.785rem;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
</style>
