<script setup lang="js">
import { onMounted } from 'vue';
import { useServiceGroupStore } from '@/stores/serviceGroup.store';
import { useRouteParams } from '@vueuse/router';
import { storeToRefs } from 'pinia';
import ServiceGroupModal from '@/components/modals/ServiceGroupModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import useModal from '@/stores/modal.store';
import { useProjectStore } from '@/stores/project.store';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import Search from '@/components/Search.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const serviceGroupStore = useServiceGroupStore();
const projectId = useRouteParams('projectId');
const { activeModal, openModal, closeModal } = useModal();
const {
  searchName,
  selectedServiceGroups,
  isSelectGroupAll,
  getServiceGroupListRequest,
  serviceGroup,
  paginatedServiceGroupList
} = storeToRefs(serviceGroupStore);
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

onMounted(() => {
  searchName.value = '';
  serviceGroupStore.getPaginatedServiceGroupList(projectId.value);
});
function selectItem(itemId) {
  if (selectedServiceGroups.value.includes(itemId)) {
    selectedServiceGroups.value = selectedServiceGroups.value.filter(
      (service) => service != itemId
    );
  } else {
    selectedServiceGroups.value.push(itemId);
  }
}
function selectAll() {
  const isActive = isSelectGroupAll.value;
  if (!isActive) {
    const groups = paginatedServiceGroupList.value.data;
    groups.forEach((service) => {
      if (!selectedServiceGroups.value.includes(service.id)) {
        selectedServiceGroups.value.push(service.id);
      }
    });
  } else {
    selectedServiceGroups.value = [];
  }
  isSelectGroupAll.value = !isActive;
}
function handleClick(group, modal) {
  serviceGroup.value = group;
  activeModal.value = modal;
}
function handlePageChange(page) {
  serviceGroupStore.getPaginatedServiceGroupList(projectId.value, page);
}
async function deleteServiceGroup() {
  await serviceGroupStore.deleteServiceGroup(projectId.value, serviceGroup.value.id);
  serviceGroupStore.getPaginatedServiceGroupList(projectId.value);
  closeModal();
}

function redirectTo(projectId, serviceGroupId) {
  router.push({
    name: 'service-group-sub-list',
    params: {
      projectId,
      serviceGroupId
    }
  });
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex justify-content-between mb-4 flex-wrap projects_filter-block">
        <div class="d-flex align-items-center flex-wrap">
          <Search
            @search="serviceGroupStore.getPaginatedServiceGroupList(projectId)"
            :value="searchName"
            :placeholderText="'Поиск по названию'"
            @input="searchName = $event.target.value"
          />
          <!-- <Sort :value="sortType" @applyChange="changeSortType" />
          <span class="delete-selected-btn" @click="deleteAll()" v-show="selectedServices.length"
            >Удалить выбранные</span
          > -->
        </div>
        <div class="d-flex align-items-center">
          <Bootstrap5Pagination
            :data="paginatedServiceGroupList"
            @pagination-change-page="handlePageChange"
            :show-disabled="true"
            :limit="2"
          />
          <div @click="openModal('serviceGroupCreateModal')" class="orange-btn">
            <img src="@/assets/img/plus-ico.svg" alt="" /> Создать группу
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table gray-table">
          <thead>
            <tr>
              <th class="checkbox-td">
                <span
                  @click="selectAll()"
                  class="table-checkbox"
                  :class="{ active: isSelectGroupAll }"
                />
              </th>
              <th>Наименование группы</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="getServiceGroupListRequest.isLoading">
              <td colspan="5">
                <div class="d-flex justify-content-center">
                  <img class="loader" src="@/assets/img/loader.gif" alt="loader.gif" />
                </div>
              </td>
            </tr>
            <tr
              v-for="serviceGroup in paginatedServiceGroupList.data"
              :key="serviceGroup.id"
              :class="{ selected: selectedServiceGroups.includes(serviceGroup.id) }"
              @click="redirectTo(projectId, serviceGroup.id)"
            >
              <td class="checkbox-td">
                <span @click.stop="selectItem(serviceGroup.id)" class="table-checkbox name"></span>
              </td>
              <td>
                {{ serviceGroup.title[project.default_locale] }}
              </td>
              <td>
                <div class="table-buttons">
                  <div
                    class="table-button"
                    @click.stop="handleClick(serviceGroup, 'serviceGroupUpdateModal')"
                  >
                    <span class="edit-ico" />
                  </div>
                  <div class="table-button" @click.stop="handleClick(serviceGroup, 'deleteModal')">
                    <span class="delite-ico" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <DeleteModal
        v-if="activeModal === 'deleteModal'"
        :itemText="'группу'"
        :title="serviceGroup.title[project.default_locale]"
        @confirmDelete="deleteServiceGroup()"
      />
      <ServiceGroupModal
        v-if="
          activeModal === 'serviceGroupCreateModal' || activeModal === 'serviceGroupUpdateModal'
        "
        @action="serviceGroupStore.getPaginatedServiceGroupList(projectId);"
      />
    </div>
  </div>
</template>
