<script setup lang="js">
import { onMounted, ref } from 'vue';
import { useProjectStore } from '@/stores/project.store';
import { useNodeStore } from '@/stores/node.store';
import { useRouteParams } from '@vueuse/router';
import ModalTemplateSide from '@/components/modals/ModalTemplateSide.vue';
import useModal from '@/stores/modal.store';
import BasicToggleSwitch from '@/components/toggle-switch.vue';
import { storeToRefs } from 'pinia';

const projectStore = useProjectStore();
const nodeStore = useNodeStore();
const projectId = useRouteParams('projectId');
const bpId = useRouteParams('bpId');
const { closeModal } = useModal();
const { project } = storeToRefs(projectStore);
const { roleList, directionTypeList, bpNode, createNodeRequest } = storeToRefs(nodeStore);
const title = ref({});
const monitoring_title = ref({});
const processing_title = ref({});
const note = ref('');
const name = ref('');
const role = ref('');
const direction_type = ref('');
const direction_field_title = ref({});
const direction_title = ref({});
const set_reject = ref(false);
const delegate = ref(false);

onMounted(() => {
  nodeStore.clearRequestVariables();
  if (!project.locales) {
    projectStore.getProject(projectId.value);
  }
});

function nodeName(bpNode) {
  let title = bpNode?.current?.node?.title;
  return title[project.value.default_locale];
}

function createNode() {
  if (direction_type.value != 'one') {
    direction_field_title.value = null;
  }
  if (!isShowDirectionTextField()) {
    direction_title.value = null;
  }
  nodeStore
    .createNode(
      projectId.value,
      bpId.value,
      title.value,
      processing_title.value,
      monitoring_title.value,
      name.value,
      note.value,
      role.value,
      direction_type.value,
      bpNode?.value.id,
      direction_field_title.value,
      direction_title.value,
      set_reject.value,
      delegate.value
    )
    .then(() => {
      closeModal();
    });
}

function isShowDirectionTextField() {
  if (Object.keys(bpNode.value).length) {
    if (bpNode.value.current.node.direction_type == 'one') {
      return true;
    }
  }
  return false;
}
function isErrorField(fieldName) {
  if (createNodeRequest.value.error?.errors) {
    return createNodeRequest.value.error.errors[fieldName] || false;
  }
}
function isNotFieldError() {
  if (!createNodeRequest.value.error?.errors) {
    return createNodeRequest.value.error;
  }
}
</script>

<template>
  <ModalTemplateSide @submit="createNode" :disableButtons="createNodeRequest.isLoading">
    <template v-slot:title>
      <span v-if="Object.keys(bpNode).length">
        Создать дочерний узел от {{ nodeName(bpNode) }}
      </span>
      <span v-else>Создать узел</span>
    </template>
    <template v-slot:body>
      <!-- System name -->
      <div class="mt-3">
        <h5>Системное название узла</h5>
        <input
          type="text"
          v-model="name"
          class="form-control"
          :class="{ error: isErrorField('name') }"
        />

        <p v-if="isErrorField('name')" class="error-mesage">
          <span v-for="error in isErrorField('name')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>

      <!-- Interface name for settings -->
      <div class="mt-3">
        <h5>Интерфейсное название для настройки рабочих мест</h5>

        <input
          v-for="locale in project.locales"
          :key="locale.id"
          type="text"
          v-model="title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('title') }"
          :placeholder="locale.name"
          :title="locale.name"
        />

        <p v-if="isErrorField('title')" class="error-mesage">
          <span v-for="error in isErrorField('title')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>

      <!-- Interface name for monitoring -->
      <div class="mt-3">
        <h5>Интерфейсное название для мониторинга</h5>

        <input
          v-for="locale in project.locales"
          :key="locale.id"
          type="text"
          v-model="monitoring_title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('monitoring_title') }"
          :placeholder="locale.name"
          :title="locale.name"
        />

        <p v-if="isErrorField('monitoring_title')" class="error-mesage">
          <span v-for="error in isErrorField('monitoring_title')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>

      <!-- Interface name for processing -->
      <div class="mt-3">
        <h5>Интерфейсное название для группировки полей</h5>

        <input
          v-for="locale in project.locales"
          :key="locale.id"
          type="text"
          v-model="processing_title[locale.id]"
          class="form-control"
          :class="{ error: isErrorField('processing_title') }"
          :placeholder="locale.name"
          :title="locale.name"
        />

        <p v-if="isErrorField('processing_title')" class="error-mesage">
          <span v-for="error in isErrorField('processing_title')" :key="error">
            {{ error }}
          </span>
        </p>
      </div>

      <!-- Role -->
      <div class="mt-3">
        <h5>Роль</h5>
        <select v-model="role" class="form-select" :class="{ error: isErrorField('role') }">
          <option v-for="roleKey in Object.keys(roleList)" :key="roleKey" :value="roleKey">
            {{ roleList[roleKey] }}
          </option>
        </select>
        <p v-if="isErrorField('role')" class="error-mesage">
          <span v-for="error in isErrorField('role')" :key="error">{{ error }}</span>
        </p>
      </div>

      <!-- Direction type -->
      <div class="mt-3">
        <h5>Направление</h5>

        <select
          v-model="direction_type"
          class="form-select"
          :class="{ error: isErrorField('direction_type') }"
        >
          <option
            v-for="directionTypeKey in Object.keys(directionTypeList)"
            :key="directionTypeKey"
            :value="directionTypeKey"
          >
            {{ directionTypeList[directionTypeKey] }}
          </option>
        </select>

        <p v-if="isErrorField('direction_type')" class="error-mesage">
          <span v-for="error in isErrorField('direction_type')" :key="error">{{ error }}</span>
        </p>
      </div>

      <!-- Direction field title -->
      <template v-if="direction_type == 'one'">
        <div class="mt-3">
          <h5>Название поля направления</h5>

          <input
            v-for="locale in project.locales"
            :key="locale.id"
            type="text"
            v-model="direction_field_title[locale.id]"
            class="form-control"
            :class="{ error: isErrorField('direction_field_title') }"
            :placeholder="locale.name"
            :title="locale.name"
          />

          <p v-if="isErrorField('direction_field_title')" class="error-mesage">
            <span v-for="error in isErrorField('direction_field_title')" :key="error">{{
              error
            }}</span>
          </p>
        </div>
      </template>

      <!-- Direction title -->
      <template v-if="isShowDirectionTextField()">
        <div class="mt-3">
          <h5>Текст при выборе направления</h5>

          <input
            v-for="locale in project.locales"
            :key="locale.id"
            type="text"
            v-model="direction_title[locale.id]"
            class="form-control"
            :class="{ error: isErrorField('direction_title') }"
            :placeholder="locale.name"
            :title="locale.name"
          />

          <p v-if="isErrorField('direction_title')" class="error-mesage">
            <span v-for="error in isErrorField('direction_title')" :key="error">{{ error }}</span>
          </p>
        </div>
      </template>

      <!-- Set reject -->
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Установить статус отказано</label>
        <basic-toggle-switch v-model="set_reject" />
      </div>

      <!-- Delegate -->
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <label class="form-label">Делегирование</label>
        <basic-toggle-switch v-model="delegate" />
      </div>

      <!-- Common errors -->
      <p v-if="isNotFieldError()" class="error-mesage">
        {{ isNotFieldError() }}
      </p>
    </template>
    <template v-slot:submit>Добавить</template>
  </ModalTemplateSide>
</template>
