import { inject, ref } from 'vue';
import { defineStore } from 'pinia';
import baseAxios from 'axios';

import { AXIOS } from '../const';

import * as api from '../router/endpoints';

export const useServiceGroupStore = defineStore('serviceGroup', () => {
  const axios = inject(AXIOS) || baseAxios;

  const searchName = ref('');
  const sortType = ref('1');
  const serviceGroup = ref({});
  const selectedSubItem = ref({});
  const serviceGroupList = ref([]);
  const selectedServiceGroups = ref([]);
  const selectedServiceGroupSubs = ref([]);
  const isSelectGroupAll = ref(false);
  const isSelectGroupSubAll = ref(false);
  const paginatedServiceGroupList = ref([]);
  const paginatedServiceGroupSubList = ref([]);
  const serviceGroupSubType = ref('undefined');
  const currentParentGroup = ref({});

  const getServiceGroupListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceGroupSubListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const createServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const deleteServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const updateServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getServiceGroupRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const getGroupAvailableServiceListRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const attachServicesRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  const detachServicesRequest = ref({
    isLoading: false,
    isFinished: false,
    error: null
  });

  function getPaginatedServiceGroupList(projectId, page = 1) {
    getServiceGroupListRequest.value = {};
    const req = getServiceGroupListRequest.value;
    req.isLoading = true;
    paginatedServiceGroupList.value = [];
    return axios
      .get(api.serviceGroups({ projectId }), {
        params: { page, search: searchName.value }
      })
      .then((resp) => {
        paginatedServiceGroupList.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getPaginatedServiceGroupSubList(projectId, serviceGroupId, page = 1) {
    getServiceGroupSubListRequest.value = {};
    const req = getServiceGroupSubListRequest.value;
    req.isLoading = true;
    paginatedServiceGroupSubList.value = [];
    return axios
      .get(api.serviceGroupSubs({ projectId, serviceGroupId }), {
        params: { page, search: searchName.value }
      })
      .then((resp) => {
        paginatedServiceGroupSubList.value = resp.data.items;
        serviceGroupSubType.value = resp.data.type;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function createServiceGroup(projectId, title, parentGroupId) {
    createServiceGroupRequest.value = {};
    const req = createServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .post(api.serviceGroups({ projectId }), {
        title,
        parent_id: parentGroupId
      })
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function deleteServiceGroup(projectId, serviceGroupId) {
    deleteServiceGroupRequest.value = {};
    const req = deleteServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .delete(api.serviceGroupById({ projectId, serviceGroupId }))
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }
  function updateServiceGroup(projectId, serviceGroupId, title) {
    updateServiceGroupRequest.value = {};
    const req = updateServiceGroupRequest.value;
    req.isLoading = true;

    return axios
      .patch(api.serviceGroupById({ projectId, serviceGroupId }), { title })
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getServiceGroup(projectId, serviceGroupId) {
    getServiceGroupRequest.value = {};
    const req = getServiceGroupRequest.value;
    req.isLoading = true;
    serviceGroup.value = {};
    return axios
      .get(api.serviceGroupById({ projectId, serviceGroupId }))
      .then((resp) => {
        serviceGroup.value = resp.data;
        return resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function getGroupAvailableServiceList(projectId, serviceGroupId, search) {
    getGroupAvailableServiceListRequest.value = {};
    const req = getGroupAvailableServiceListRequest.value;
    req.isLoading = true;
    return axios
      .get(api.serviceGroupAvailableServices({ projectId, serviceGroupId }), {
        params: { search, paginated: 0 }
      })
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function attachServices(projectId, serviceGroupId, serviceIds) {
    attachServicesRequest.value = {};
    const req = attachServicesRequest.value;
    req.isLoading = true;
    return axios
      .post(api.serviceGroupServices({ projectId, serviceGroupId }), {
        service_ids: serviceIds
      })
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  function detachServices(projectId, serviceGroupId, serviceIds) {
    detachServicesRequest.value = {};
    const req = detachServicesRequest.value;
    req.isLoading = true;
    return axios
      .delete(api.serviceGroupServices({ projectId, serviceGroupId }), {
        data: { service_ids: serviceIds }
      })
      .then((resp) => {
        req.value = resp.data;
      })
      .catch((e) => {
        req.error = e;
        throw e;
      })
      .finally(() => {
        req.isFinished = true;
        req.isLoading = false;
      });
  }

  return {
    getPaginatedServiceGroupList,
    createServiceGroup,
    deleteServiceGroup,
    updateServiceGroup,
    getServiceGroup,
    getPaginatedServiceGroupSubList,
    getGroupAvailableServiceList,
    attachServices,
    detachServices,

    attachServicesRequest,
    detachServicesRequest,
    getGroupAvailableServiceListRequest,
    selectedSubItem,
    paginatedServiceGroupSubList,
    serviceGroupSubType,
    paginatedServiceGroupList,
    getServiceGroupListRequest,
    getServiceGroupSubListRequest,
    createServiceGroupRequest,
    deleteServiceGroupRequest,
    updateServiceGroupRequest,
    getServiceGroupRequest,
    serviceGroupList,
    serviceGroup,
    selectedServiceGroups,
    isSelectGroupAll,
    selectedServiceGroupSubs,
    isSelectGroupSubAll,
    searchName,
    sortType,
    currentParentGroup
  };
});
